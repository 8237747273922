import React from 'react'
import { Link } from 'gatsby'
import Layout from '~/components/layout'
import Button from '~/components/button'

const Cancel = () => {
  return (
    <Layout>
      <div className="h-full">
        <div className={`grid grid-cols-12 container laptop:m-auto`}>
          <div className="col-start-1 col-end-13 min-h-screen text-center push-content-alt">
            <img
              className="mx-auto pt-10"
              width={60}
              src="/failure.png"
              alt="red cross"
            />
            <h1 className="my-2">Votre paiement a bien été annulé</h1>
            <p className="mb-4">
              Vous n'avez pas été débité. Merci de{' '}
              <a href="mailto:ventes@dufreche.fr">
                contacter l'étude de Me Dufrêche
              </a>{' '}
              si vous rencontrez des difficultés.
            </p>
            <Link to="/">
              <Button label="Retour à l'accueil" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Cancel
